import axios from "axios";
import { API, endpointBuilder } from "../API";

let path = API.path.Home.homeTagLine;

export let getAll = async () => {
  try {
    return await axios.get(endpointBuilder(path),{ cache: false });
  } catch (error) {
    return;
  }
};