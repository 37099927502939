import axios from "axios";
import { API, endpointBuilder } from "../API";

let path = API.path.Careers.contract;

export let getAll = async () => {
  try {
    return await axios.get(endpointBuilder(path));
  } catch (error) {}
};
