import React, { useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import "./Nav.css";
import { setNavbar, checkNavPath, navExpand } from "../../Controller/Navbar";
const Nav = ({ navigation }) => {
  useEffect(() => {
    checkNavPath();
  }, []);
  return (
    <>
      <div className="navbar">
        <Link
          onClick={() => {
            setNavbar("/");
          }}
          to="/"
        >
          <img
          id="app-logo"
            src="asset\Logo Ripples\Logo.png"
            alt="logo"
            width={167}
            height={38}
          />
        </Link>
        <div className="nav-box">
          {navigation.map((element) => (
            <Link
              key={element.path}
              onClick={() => {
                setNavbar(element.path);
              }}
              to={element.path}
              className="nav-link main-font font-20 bold-600"
              id={element.path}
            >
              {element.val}
            </Link>
          ))}
        </div>
        <div id="nav-collapse" onClick={navExpand}>
          <div className="bar-btn"></div>
          <div className="bar-btn"></div>
        </div>
        <div id="nav-collapse-box">
          {navigation
            .filter((e) => e.path !== "contact")
            .map((element) => (
              <Link
                key={element.path}
                onClick={() => {
                  setNavbar(element.path);
                  navExpand();
                }}
                to={element.path}
                className="nav-link nav-link-collapse main-font font-32 bold-600"
                id={element.path}
              >
                {element.val}
              </Link>
            ))}
          <Link
            key={"contact"}
            onClick={() => {
              setNavbar("contact");
              navExpand();
            }}
            to={"contact"}
            className="nav-link nav-link-collapse main-font font-32 bold-600"
            id={"contact"}
          >
            <div>
            {"Contact"}
            <img src="\Arrow 2.svg" alt="" />
            </div>
          </Link>
        </div>
      </div>
      <Outlet></Outlet>
    </>
  );
};

export default Nav;
