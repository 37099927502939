const url = process.env.REACT_APP_SERVICE_ENDPOINT;
export let API = {
  // host: "http://localhost:5000",
  host: url,
  path: {
    About: {
      member: "/aboutMember",
    },
    Careers: {
      careers: "/career",
      member: "/careerMember",
      city: "/city",
      contract: "/contract",
      department: "/department",
    },
    Contact: {
      contact: "/contact",
    },
    Home: {
      homeTagLine: "/homeTagLine",
      partner: "/partner",
    },
    Solution: {
      solutionGroup: "/solutionGroup",
      solution: "/solution",
    },
  },
};
export let imgSource = API.host + "/file/";
export function endpointBuilder(path) {
  return API.host.concat(path);
}


