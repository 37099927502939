import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import arrow1 from "./asset/Arrow 3.svg";
import arrow2 from "./asset/Arrow 2.svg";
import "./dropDown.css";
import "./index.css";
import { getAll as getAllCity } from "../../Controller/API/Careers/City";
import { getAll as getDepartment } from "../../Controller/API/Careers/Department";
import { getAll as getMembers } from "../../Controller/API/Careers/Member";
import { imgSource } from "../../Controller/API/API";
const Careers = () => {
  let [city, setCity] = useState();
  let [departnent, setDepartMent] = useState();
  const [cityData, setcityData] = useState([]);
  const [departmentData, setdepartmentData] = useState([]);
  const [membersData, setmembersData] = useState([]);
  let jQueryCode = () => {
    var toggle = false;
    $("select").each(function () {
      var $this = $(this),
        numberOfOptions = $(this).children("option").length;
      $this.addClass("select-hidden");
      $this.wrap('<div class="select"></div>');
      $this.after(
        '<div class="select-styled  careers-select bg-transparent main-font color-secondary font-32 bold-600"></div>'
      );

      var $styledSelect = $this.next("div.select-styled");
      $styledSelect.text($this.children("option").eq(0).text());
      var $list = $("<ul />", {
        class: "select-options",
      }).insertAfter($styledSelect);

      for (var i = 0; i < numberOfOptions; i++) {
        $("<li  />", {
          text: $this.children("option").eq(i).text(),
          rel: $this.children("option").eq(i).val(),
        })
          .addClass(
            "is-selected bg-transparent main-font color-secondary font-16 bold-600"
          )
          .appendTo($list);
        if ($this.children("option").eq(i).is(":selected")) {
          $('li[rel="' + $this.children("option").eq(i).val() + '"]').addClass(
            "is-selected bg-transparent main-font color-secondary font-16 bold-600"
          );
        }
      }

      var $listItems = $list.children("li");

      $styledSelect.click(function (e) {
        e.stopPropagation();
        $("div.select-styled.active")
          .not(this)
          .each(function () {
            toggle = !toggle;
            $(".careers-select.active").css(
              "background-image",
              'url("' + encodeURIComponent(arrow1) + '")'
            );
            $(this).removeClass("active").next("ul.select-options").hide();
          });
        if (!toggle) {
          $(this)
            .addClass("active")
            .next("ul.select-options")
            .css("display", "flex");
          $(".careers-select.active").css(
            "background-image",
            'url("' + encodeURIComponent(arrow2) + '")'
          );
        } else {
          $(this).removeClass("active").next("ul.select-options").hide();
          $(".careers-select").css(
            "background-image",
            'url("' + encodeURIComponent(arrow1) + '")'
          );
        }
        toggle = !toggle;
      });

      $listItems.click(function (e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass("active");
        toggle = !toggle;

        $this.val($(this).attr("rel"));
        $list.find("li.is-selected").removeClass("is-selected");
        $list
          .find('li[rel="' + $(this).attr("rel") + '"]')
          .addClass("is-selected");
        $list.hide();
        $(".careers-select").css(
          "background-image",
          'url("' + encodeURIComponent(arrow1) + '")'
        );

        if ($this.attr("name") === "city-select") {
          setCity($this.val());
        }
        if ($this.attr("name") === "department-select") {
          setDepartMent($this.val());
        }
      });

      $(document).click(function () {
        toggle = false;
        $styledSelect.removeClass("active");
        $(".careers-select").css(
          "background-image",
          'url("' + encodeURIComponent(arrow1) + '")'
        );
        $list.hide();
      });
    });
  };
  useEffect(() => {
    getAllCity().then((res) => {
      setcityData(res.data.map((city) => city.name));
    });
    getDepartment().then((res) => {
      setdepartmentData(res.data.map((e) => e.type));
    });
    getMembers().then((res) => {
      setmembersData(
        res.data
          .map((member) => {
            member.profile = imgSource + member.profile;
            return member;
          })
          .sort((a, b) => a.index - b.index)
      );
    });
  }, []);
  useEffect(() => {
    if (cityData?.length > 0 && departmentData?.length > 0) {
      jQueryCode();
    }
  }, [cityData, departmentData]);

  return (
    <div className="layout">
      <div className="main-container">
        <div id="careers-top-banner" className="top-banner">
          <div className="top-banner-cover"></div>
          <div id="careers-content-box">
            <div
              id="careers-text"
              className="main-font color-secondary bold-800 font-62"
            >
              Join us.
              <br />
              Create your ripple effect.
            </div>
            <form name="filter" action="/careers/" id="carreers-form">
              <div id="careers-select-box">
                <select
                  name="city-select"
                  id="city-select"
                  className="careers-select bg-transparent main-font color-secondary font-32 bold-600"
                >
                  <option value="" className="hide" disabled selected>
                    City
                  </option>
                  {cityData.map((city) => (
                    <option
                      value={city}
                      className="cereers-select-option main-font font-16 bold-600 bg-transparent"
                    >
                      {city}
                    </option>
                  ))}
                </select>
                <select
                  name="department-select"
                  id="department-select"
                  className="careers-select bg-transparent main-font color-secondary font-32 bold-600"
                >
                  <option value="" className="hide" disabled selected>
                    Department
                  </option>
                  {departmentData.map((data) => (
                    <option
                      value={data}
                      className="cereers-select-option main-font font-16 bold-600 bg-transparent"
                    >
                      {data}
                    </option>
                  ))}
                </select>
              </div>
              <Link
                to={`find-careers/${city ? city + "/" : "all/"}${
                  departnent ? departnent : "all"
                }`}
                className="bg-transparent main-font color-secondary font-32 bold-600"
              >
                <img src="\Go Jops.svg" alt="find" width={57} height={35} />
              </Link>
            </form>
          </div>
        </div>
        <div id="careers-family-section">
          <div className="main-font font-48 bold-800 color-primary">
            Meet the Ripples family
          </div>
          <div id="member-box">
            {membersData.map((e, i) => (
              <div className="member-card">
                <img
                  key={i}
                  src={e.profile}
                  alt={i}
                  className="family-member"
                ></img>
                <div className="member-cover">
                  <div className="member-info">
                    <div className="main-font font-24 bold-700 color-secondary">
                      {e.name}
                    </div>
                    <div className="main-font font-14 bold-600 color-secondary">
                      {e.role}
                    </div>
                    <div className="main-font font-12 bold-600 color-secondary">
                      {e?.qoute}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="asset" id="asset1"></div>
          <div className="asset" id="asset2"></div>
          <div className="asset" id="asset3"></div>
          <div className="asset" id="asset4"></div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
