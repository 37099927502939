import { useEffect, useState } from "react";
import "./index.css";

import { getAll } from "../../Controller/API/About";
import { imgSource } from "../../Controller/API/API";

const About = () => {
  const [memberData, setMemberData] = useState([]);
  useEffect(() => {
    getAll().then((res) => {
      let data =res.data?.map((e)=>{
        e.profile = imgSource + e.profile
        return e
      }).sort((a, b) => a.index - b.index);
      setMemberData(data)
    });
  }, []);
  
  return (
    <div className="layout">
      <div className="main-container">
        <div id="about-top-banner" className="top-banner">
          <div className="top-banner-cover"></div>
          <div id="top-banner-text-box" className="bg-primary">
            <div id="top-banner-text-box-layout">
              <div
                id="top-banner-text-topic"
                className="main-font font-40 bold-800 color-secondary"
              >
                Who is Ripples
              </div>
              <div
                id="top-banner-text"
                className="main-font font-16 bold-500 color-secondary"
              >
                Ripples is born from the belief that every ripple can become a
                wave. Our founders are industry experts with years of experience
                helping brands achieve success on multiple eCommerce channels,
                such as Shopee, Lazada, TikTok, Facebook, and more. Their
                passion for innovation and drive to reshape the eCommerce
                landscape form the foundation of Ripples.
              </div>
            </div>
          </div>
        </div>
        <div id="member-section">
          {memberData?.map((member, index) => (
            <ProfileBox
              key={index}
              index={index}
              name={member.name}
              surname={member.surname}
              description={member.description}
              img={member.profile}
              role={member.role}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const ProfileBox = ({ index, name, surname, role, description, img }) => {
  return (
    <div className="member-card-container">
      <div
        id={index % 2 === 0 ? "member-card-bar-right" : "member-card-bar-left"}
        className="member-card-bar"
      ></div>
      <div
        className={
          "member-card-profile " + (index % 2 === 0 ? "" : "row-reverse")
        }
      >
        <img src={img} alt="" className="member-card-profile-img"></img>
        <div className="member-card-box">
          <div>
            <div className="member-card-name main-font bold-800 color-secondary font-50">
              {name}
            </div>
            <div className=" main-font bold-800 color-secondary font-36">
              {surname}
            </div>
          </div>
          <div className=" main-font bold-700 color-secondary font-16 role">
            {role}
          </div>
          <div className="member-card-descriptione  main-font bold-500 color-secondary font-16">
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
