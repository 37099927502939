import axios from "axios";
import { API, endpointBuilder } from "../API";

let path = API.path.Careers.city;

export let getAll = async () => {
  try {
    return await axios.get(endpointBuilder(path), { cache: false });
  } catch (error) {}
};
