import { useState,useEffect } from "react";
import emailjs from "@emailjs/browser";
import "./index.css";
import emailIcon from "./asset/2eaa63adb5b562872dad64d7e21b15b8.png";
import phoneIcon from "./asset/phone.svg";
import { getAll } from "../../Controller/API/Contact/Addr";
const Contact = () => {
  const [data, setData] = useState();
  useEffect(() => {
    getAll().then(res=>{
      setData(res.data[0])
    })
  }, [])
  
  const handleFormSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      "service_ripples888",
      "template_zkl7mmf",
      "#contact-form",
      "8Pd-pmjB_lOalEWuJ"
    );
  };
  return (
    <div id="contact-page-layout">
      <div id="contact-section-form">
        <img
          alt=""
          src="\asset\Asset Other\Subtract.svg"
          className="contact-asset"
        ></img>
        <div id="contact-box">
          <div id="contact-box-layout">
            <div id="contact-topic-box">
              <div className="main-font font-24 bold-700">
                Ready to start your eCommerce Journey?
              </div>
              <div className="main-font font-48 bold-800">Contact our team</div>
            </div>
            <form id="contact-form" onSubmit={handleFormSubmit}>
              <div>
                <div>
                  <label htmlFor="fname" className="main-font font-14 bold-500">
                    First name
                  </label>
                  <input type="text" id="fname" name="fname" />
                </div>
                <div>
                  <label htmlFor="lname" className="main-font font-14 bold-500">
                    Last name
                  </label>
                  <input type="text" id="lname" name="lname" />
                </div>
              </div>
              <div>
                <div>
                  <label htmlFor="email" className="main-font font-14 bold-500">
                    Email<span className="requireStar">*</span>
                  </label>
                  <input type="text" required id="email" name="email" />
                </div>
                <div>
                  <label htmlFor="phone" className="main-font font-14 bold-500">
                    Phone<span className="requireStar">*</span>
                  </label>
                  <input type="text" required id="phone" name="phone" />
                </div>
              </div>
              <div>
                <div>
                  <label
                    htmlFor="company"
                    className="main-font font-14 bold-500"
                  >
                    Company
                  </label>
                  <input type="text" id="company" name="company" />
                </div>
              </div>
              <div>
                <div>
                  <label
                    htmlFor="message"
                    className="main-font font-14 bold-500"
                  >
                    Message
                  </label>
                  <textarea form="contact-form" id="message" name="message" />
                </div>
              </div>
            </form>
            <div id="contact-btn-box">
              <button
                className="main-font font-24 bold-700"
                id="contact-btn"
                name="contactBtn"
                type="submit"
                form="contact-form"
              >
                CONTACT US <img src="\Arrow 2.svg" alt="arrow" />
              </button>
            </div>
          </div>
          <div id="contact-img">
            <div id="contact-img-cover"></div>
          </div>
        </div>
      </div>
      <div id="contact-section-location">
        <div id="location-tab-box">
          <div id="location-tab">
            <div className="tab main-font font-24 bold-600">
              Thailand Office
            </div>
          </div>
        </div>
        <div id="location-container">
          <div id="location-box">
            <div className="main-font bold-800 font-24">Address</div>
            <div id="location-address" className="main-font bold-500 font-16">
              <div className="main-font bold-700 font-16">{data?.building}</div>
              <div>{data?.address}</div>
            </div>
            <div className="contact-locaion main-font bold-500 font-16">
              <div className="">
                <img src={emailIcon} width={20} height={20} alt="" />
                {data?.email}
              </div>
              <div>
                <img src={phoneIcon} width={20} height={20} alt="" />
                {data?.tel}
              </div>
            </div>
          </div>

          <iframe
          src={data?.source.split('"')[1]}
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
