import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { setNavbar } from "../../Controller/Navbar";
const Footer = ({ navigation }) => {
  return (
    <div id="footer-container">
      <div className="footer-box">
        <img
          id="footer-icon"
          src="asset\Logo Ripples\Logo White.png"
          alt="Ripples-logo"
          width={"265px"}
          height={"67px"}
        />
        <div
          id="ripples-co"
          className="hide-860 main-font font-16 bold-500 color-secondary"
        >
          RIPPLESCOMMERCE CO.,LTD.
        </div>
        <div className="hide-860 main-font font-16 bold-500 color-secondary">
          Initiate your E-Commerce wave.
        </div>
        <div
          id="footer-social"
          className="hide-860 main-font font-24 bold-800 color-secondary "
        >
          Follow us at
        </div>
        <div  className="hide-860 footer-social-box">
          <a href="https://www.facebook.com/ripplescommerce/">
            <img
              src="asset\ICON\facebook.png"
              alt="facebook"
              width={14}
              height={26}
            />
          </a>
          <a href="https://www.linkedin.com/company/ripples-commerce/about/?viewAsMember=true">
            <img
              src="asset\ICON\linkedin.png"
              alt="linkedin"
              width={35} 
            />
          </a>
          <a href="https://www.instagram.com/ripplescommerce/">
            <img
              src="asset\ICON\instagram_717392.svg"
              alt="ig"
              width={25}
            />
          </a>
          <a href="https://www.tiktok.com/@ripplescommerce">
            <img
              src="asset\ICON\tik-tok_3046120.svg"
              alt="tiktok"
              width={25}
            />
          </a>
        </div>
      </div>
      <div className="footer-box">
        <div id="footer-address-box">
          <div className="main-font color-secondary font-24 bold-800">
            Address
          </div>
          <div
            id="footer-address"
            className="main-font color-secondary font-16 bold-500"
          >
            <div className="main-font color-secondary font-20 bold-600">
              Kongboonma Building
            </div>
            699 Si Lom, Silom, Bang Rak, Bangkok 10500, Thailand
          </div>
        </div>

        <div className="main-font color-secondary font-24 bold-800">
          Reach Us
        </div>
        <div
          id="footer-contact"
          className="main-font color-secondary font-16 bold-600"
        >
          <div className="main-font color-secondary">
            For press and Media inquiries
          </div>
          <a
            href="mailto:partnership@ripplescommerce.com"
            className="main-font color-third font-16 bold-600"
          >
            partnership@ripplescommerce.com
          </a>
        </div>
        <div
          id="footer-social"
          className="show-860 main-font font-24 bold-800 color-secondary "
        >
          Follow us at
        </div>
        <div className="footer-social-box show-860">
          <a href="https://www.facebook.com/ripplescommerce/">
            <img
              src="asset\ICON\facebook.png"
              alt="facebook"
              width={14}
              height={26}
            />
          </a>
          <a href="https://www.linkedin.com/company/ripples-commerce/about/?viewAsMember=true">
            <img
              src="asset\ICON\linkedin.png"
              alt="linkedin"
              width={35}
              height={34}
            />
          </a>
          <a href="https://www.instagram.com/ripplescommerce/">
            <img
              src="asset\ICON\instagram_717392.svg"
              alt="ig"
              width={28}
            />
          </a>
          <a href="https://www.tiktok.com/@ripplescommerce">
            <img
              src="asset\ICON\tik-tok_3046120.svg"
              alt="tiktok"
              width={25}
            />
          </a>
        </div>
      </div>
      <div className="footer-box">
        <div
          id="footer-navigate"
          className="main-font color-secondary font-24 bold-800"
        >
          Quick Link
        </div>
        <div id="footer-link-box">
          {navigation.map((element) => (
            <Link
              onClick={() => setNavbar(element.path)}
              key={element.path}
              to={element.path}
              className="footer-link main-font font-16 bold-700 color-third"
            >
              {element.val}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
