import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { setNavbar } from "../../Controller/Navbar";
import "./Home.css";
import { getAll as getTagline } from "../../Controller/API/Home/TagLine";
import { getAll as getPartners } from "../../Controller/API/Home/Partner";
import { imgSource } from "../../Controller/API/API";

const Home = () => {
  const [headerText, setheaderText] = useState();
  const [partners, setpartners] = useState([]);
  useEffect(() => {
    getTagline().then((res) => {
      setheaderText(res.data[0]);
    });
    getPartners().then((res) => {
      setpartners(
        res.data
          .map((partner) => {
            partner.variant1 = imgSource + partner.variant1;
            partner.variant2 = imgSource + partner.variant2;
            return partner;
          })
          .sort((a, b) => a.index - b.index)
      );
    });
  }, []);

  return (
    <div className="layout">
      <div className="top-banner" id="top-banner">
        <div className="text-container">
          <span className="color-secondary font-46 main-font bold-700">
            {headerText?.line1}
          </span>
          <br />
          <span className="color-primary font-48 main-font bold-700">
            {headerText?.line2}
          </span>
        </div>

        <div id="home-cover">
          <span className="color-secondary font-32 main-font bold-700">
            {headerText?.line1}
          </span>
          <span className="color-primary font-32 main-font bold-700">
            {headerText?.line2}
          </span>
        </div>
      </div>
      <div className="section home-solution-box">
        <div id="bar-1" className="asset"></div>
        <div id="bar-2" className="asset"></div>
        <div id="bar-3" className="asset"></div>
        <div className="strategy-container">
          <div id="strategy-decribe">
            <img src="\Pic1.svg" id="strategy-decribe-img"></img>
            <div id="strategy-decribe-textbox">
              <div className="main-font font-48 bold-800">The Ripples Way</div>
              <div className="expand main-font font-18 bold-500">
                We believe the world of commerce and creatives are converging
                and brands needs to not only sell but inspire. The rise of
                “Shoppertainment” have created a digital revolution where
                consumers can discover, engage, and participate with brands in
                ways never seen before.
                <br />
                <br />
                At Ripples we  give equal importance to content quality,
                marketing strategy and sales tactics. We focus on creating
                authentic customer experiences while ensuring we have enough
                traffic and conversion rates to meet your sales target.
              </div>
            </div>
          </div>
          <div className="home-container">
            <div className="home-card-box">
              <div className="home-card">
                <img
                  className="home-card-image"
                  src="/asset/PIC/pic4.png"
                  alt="img1"
                />
                <div className="main-font font-24 bold-800 color-primary home-card-topic">
                  Shop Management
                </div>
                <div className="main-font font-16 bold-500 color-primary ">
                  We seamlessly integrate sales and marketing strategies while
                  operating your stores at the highest standard.
                </div>
              </div>
              <div className="home-card">
                <img
                  className="home-card-image"
                  src="\asset\PIC\pic3.jfif"
                  alt="image2"
                />
                <div className="main-font font-24 bold-800 color-primary home-card-topic">
                  Online Marketing
                </div>
                <div className="main-font font-16 bold-500 color-primary">
                  Our experienced marketing team ensures optimal media
                  allocation and maximizes ad performance for the highest ROI.
                </div>
              </div>
            </div>
          </div>
          <div id="strategy-content2">
            <div className="home-card-box">
              <div className="home-card sub-card bg-primary">
                <div className="main-font font-30 bold-800 color-secondary card-topic">
                  <img src="\asset\ICON\Graphic Design.svg" alt="" />
                  Graphic Design
                </div>
                <div className="main-font font-16 bold-500 color-secondary">
                  We create eye-catching artwork and motion designs for your
                  stores and marketing campaigns.
                </div>
              </div>
              <div className="home-card sub-card bg-primary">
                <div className="main-font font-30 bold-800 color-secondary card-topic">
                  <img src="\asset\ICON\Customer Service.svg" alt="" />
                  Customer Service
                </div>
                <div className="main-font font-16 bold-500 color-secondary">
                  Our customer service team handles all your customer inquiries
                  across all online channels, 365 days a year.
                </div>
              </div>
            </div>
          </div>
          <div className="home-link-box">
            <Link
              to={"/solutions"}
              onClick={() => setNavbar("solutions")}
              className="home-link"
            >
              <span
                id="link-service"
                className="main-font font-24 bold-600 home-link-text"
              >
                See our full services
              </span>
              <img src="\Arrow 1-home.svg" alt="arrow icon" height={30} />
            </Link>
          </div>
        </div>
      </div>
      <div className="section bg-primary">
        <img
          id="circle-asset1"
          className="circle-asset"
          src="asset\Asset Other\circle.svg"
          alt="circle"
        />
        <img
          id="circle-asset2"
          className="circle-asset"
          src="asset\Asset Other\circle-right.svg"
          alt="circle"
        />
        <div className="strategy-container studio">
          <div id="studio-decribe">
            <div id="strategy-decribe-textbox">
              <div className="main-font color-secondary font-48 bold-800">
                Ripples Studio
              </div>
              <div className="main-font color-secondary font-24 bold-500">
                Create captivating Live Shows, Short-Videos, and Creator
                Contents that grab attention and drive sales at Ripples Studio.
              </div>
            </div>
          </div>
          <div className="home-container">
            <div className="home-card-box">
              <div className="home-card home-studio">
                <img
                  className="home-card-image"
                  src="/asset/PIC/pic5.png"
                  alt="img1"
                />
                <div className="main-font font-24 bold-800 color-secondary home-card-topic">
                  LIVE Streaming
                </div>
                <div className="main-font font-16 bold-500 color-secondary ">
                  Our advanced studio at the center of Bangkok boasts top-notch
                  equipment, themed rooms, and expert MCs and producers.
                </div>
              </div>
              <div className="home-card home-studio">
                <img
                  className="home-card-image "
                  src="\asset\PIC\pic6.svg"
                  alt="image2"
                  style={{ objectPosition: "100% 80%" }}
                />
                <div className="main-font font-24 bold-800 color-secondary home-card-topic">
                  Short Video
                </div>
                <div className="main-font font-16 bold-500 color-secondary">
                  Our creative teams use your brand identity, past data, and
                  current social trends to create compelling short videos.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section partner">
        <div id="partner-container">
          <div
            id="partner-topic"
            className="main-font font-48 bold-800 color-primary"
          >
            Our Partners
          </div>
          <div id="partner-box">
            <div id="partner-line">
              {partners.map((e) => (
                <img
                  key={e.id}
                  id={e.id ? e.id : ""}
                  onMouseOver={(event) => {
                    event.target.src = e.variant2;
                  }}
                  onMouseLeave={(event) => {
                    event.target.src = e.variant1;
                  }}
                  src={e.variant1}
                  alt={e.id}
                  width={e.width}
                  height={e.height}
                />
              ))}
            </div>
            {/* <div id="partner-line2">
              {partners[1].data.map((e) => (
                <img
                  key={e.name}
                  id={e.id ? e.id : ""}
                  onMouseOver={(event) => {
                    event.target.src = e.src2;
                  }}
                  onMouseLeave={(event) => {
                    event.target.src = e.src;
                  }}
                  src={e.src}
                  alt={e.name}
                  width={e.width}
                  height={e.height}
                />
              ))}
            </div> */}
          </div>
        </div>
      </div>

      <div id="section-hiring">
        <div className="hiring-cover">
          <div className="hiring-box main-font font-48 bold-800 color-secondary">
            WE ARE HIRING
          </div>
          <Link
            to={"/careers"}
            onClick={() => setNavbar("careers")}
            id="apply-btn"
            href=""
            className="main-font font-24 bold-700 color-secondary bg-primary"
          >
            APPLY NOW
            <img src="\Arrow 4.svg" alt="arrow" width={30} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
