import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./index.css";
import arrow1 from "./asset/btn-type-Department.svg";
import circle1 from "./asset/Property 1=Default.svg";
import circle2 from "./asset/Property 1=Default (1).svg";
import { getAll as getCity } from "../../Controller/API/Careers/City";
import { getAll as getAllCareer } from "../../Controller/API/Careers/Careers";
import { getAll as getContract } from "../../Controller/API/Careers/Contract";
import { getAll as getDepartment } from "../../Controller/API/Careers/Department";

const FindCareers = () => {
  const [cityRawData, setCityRawdata] = useState([]);
  const [contract, setContract] = useState([]);
  const [rawdepartment, setRawDepartment] = useState([]);
  const [cityData, setcitydata] = useState([]);
  const [data, setData] = useState([]);
  useEffect(() => {
    getContract().then((res) => {
      setContract(res.data);
    });
    getDepartment().then((res) => {
      setRawDepartment(res.data);
    });
    getCity().then((res) => {
      setCityRawdata(res.data);
      setcitydata(res.data.map((c) => c.name));
    });
  }, []);
  useEffect(() => {
    getAllCareer().then(async (res) => {
      let data = rawdepartment.map((e) => ({ group: e.type }));
      let jobs = await res.data.map((job) => ({
        index:job.index,
        id: job._id,
        job: job.job,
        city: cityRawData.filter((c) => {
          return c._id === job.cityId;
        })[0]?.name,
        contractType: contract.filter((e) => e._id === job.contractId)[0]?.type,
        department: rawdepartment.filter((e) => e._id === job.departmentId)[0]
          ?.type,
        amount: job.amount,
        open: job.openString,
        close: job.closeString,
        responsibilities: {
          describe: job.responsibilityDescription,
          list: job.responsibilityList,
        },
        basicQualifications: job.basicQualification,
      })).sort((a, b) => a.index - b.index);
      let result = data.map((data) => {
        let filJob = jobs
          .filter((job) => job.department === data.group)
        data.careers = filJob;
        return data;
      });
      setData(result);
    });
  }, [cityData, contract, rawdepartment, cityRawData]);
  const { city, department } = useParams();
  const [filteredData, setFilterData] = useState([]);
  let [filterDepartmentTab, setFilterDepartmentTab] = useState(department);
  let [toggleCity, setToggleCity] = useState(false);
  let [toggleDepartment, setToggleDepartment] = useState(false);
  let [departmentFilter, setDepartmentFilter] = useState();
  let [cityFilter, setCityFilter] = useState();
  let [amount, setAmount] = useState(filteredData?.length);
  let [showDetail, setShowDetail] = useState();
  useEffect(() => {
    setFilterData(data.flatMap((e) => e.careers));
  }, [data]);
  useEffect(() => {
    setCityFilter(
      cityData.map((e) => {
        return {
          name: e,
          selected: e === city ? true : false,
        };
      })
    );
    setDepartmentFilter(
      data.map((e) => {
        e.selected = e.group === department ? true : false;
        return e;
      })
    );
  }, [data, cityData]);
  useEffect(() => {
    let city = cityFilter
      ?.map((e) => {
        if (e.selected) {
          return e.name;
        }
      })
      .filter((e) => {
        return e;
      });

    let department = departmentFilter
      ?.map((e) => {
        if (e.selected) {
          return e.group;
        }
      })
      .filter((e) => {
        return e;
      });
    if (department?.length > 1) {
      setFilterDepartmentTab("All");
    }
    let joinData =
      department?.length > 0
        ? data.filter((e) => department?.includes(e.group))
        : data;
    joinData =
      city?.length > 0
        ? joinData
            .flatMap((data) => data.careers)
            .filter((e) => city.includes(e?.city))
        : joinData.flatMap((data) => data.careers);
    setFilterData(joinData);
    setAmount(joinData?.length);
  }, [departmentFilter, cityFilter]);
  useEffect(() => {
    if (!filterDepartmentTab) {
      setDepartmentFilter(
        data.map((e) => {
          e.selected = false;
          return e;
        })
      );
    } else if (filterDepartmentTab !== "All") {
      setDepartmentFilter(
        data.map((e) => {
          e.selected = e.group === filterDepartmentTab ? true : false;
          return e;
        })
      );
    }
  }, [filterDepartmentTab]);
  return (
    <div className="layout">
      <div id="find-careers">
        <div className="find-text main-font font-48 color-4th bold-800">
          Find Your Next Career
        </div>
        <div
          id="careers-buttons"
          className="main-font font-16 color-4th bold-600"
        >
          <div
            className={
              !filterDepartmentTab || filterDepartmentTab === "All"
                ? "department-selected"
                : ""
            }
            onClick={() => {
              setFilterDepartmentTab();
              setShowDetail();
            }}
          >
            All
          </div>
          {data?.map((item) => {
            return (
              <div
                id={item.group}
                key={item.group}
                onClick={() => {
                  setFilterDepartmentTab(item.group);
                  setShowDetail();
                }}
                className={
                  filterDepartmentTab === item.group
                    ? "department-selected"
                    : ""
                }
              >
                {item.group}
              </div>
            );
          })}
        </div>
      </div>

      {showDetail ? (
        <div className="job-detail-container">
          <JobItemDetail
            job={showDetail.job}
            city={showDetail?.city}
            contractType={showDetail.contractType}
            amount={showDetail.amount}
            open={showDetail.open}
            close={showDetail.close}
            onClick={() => {
              setShowDetail();
            }}
          />
          <JobDetail props={showDetail} />
        </div>
      ) : (
        <div id="career-container">
          <div id="career-filter">
            <div id="filter-header">
              <div className="main-font font-24 bold-600 color-4th">
                Filters
              </div>
              <div
                id="clear-filter-Btn"
                className="main-font font-10 bold-600 color-5th"
                onClick={() => {
                  setFilterDepartmentTab();
                  setCityFilter(
                    cityData?.map((e) => {
                      return {
                        name: e,
                        selected: false,
                      };
                    })
                  );
                  setDepartmentFilter(
                    data?.map((e) => {
                      e.selected = false;
                      return e;
                    })
                  );
                }}
              >
                Clear filters
              </div>
            </div>
            <div id="Department" className="filter-box">
              <div className=" main-font font-16 bold-600 color-4th">
                Department
              </div>
              <img
                onClick={() => {
                  let img = document.querySelector("#Department img");
                  if (!toggleDepartment) {
                    img.style.transitionDuration = "0.5s";
                    img.style.transform = "rotateX(180deg)";
                  } else {
                    img.style.transitionDuration = "0.5s";
                    img.style.transform = "rotateX(0deg)";
                  }
                  setToggleDepartment(!toggleDepartment);
                }}
                src={arrow1}
                alt=""
                width={30}
                height={30}
              />
            </div>
            <div className="filter-container">
              <div
                id="department-filter"
                className={toggleDepartment ? "expanded-filter" : ""}
              >
                {departmentFilter?.map((e) => (
                  <div
                    key={e.group}
                    className="filter-item-box main-font font-14 bold-600 color-4th"
                  >
                    <img
                      onClick={() => {
                        let filter = departmentFilter;

                        setDepartmentFilter(
                          filter?.map((i) => {
                            if (e.group === i.group) {
                              i.selected = !i.selected;
                            }
                            return i;
                          })
                        );
                      }}
                      src={!e.selected ? circle1 : circle2}
                      alt=""
                      width={15}
                      height={15}
                    />
                    <div>{e.group}</div>
                  </div>
                ))}
              </div>
            </div>
            <div id="City" className="filter-box">
              <div className=" main-font font-16 bold-600 color-4th">City</div>
              <img
                onClick={() => {
                  let img = document.querySelector("#City img");
                  if (!toggleCity) {
                    img.style.transitionDuration = "0.5s";
                    img.style.transform = "rotateX(180deg)";
                  } else {
                    img.style.transitionDuration = "0.5s";
                    img.style.transform = "rotateX(0deg)";
                  }
                  setToggleCity(!toggleCity);
                }}
                src={arrow1}
                alt=""
                width={30}
                height={30}
              />
            </div>
            <div className="filter-container">
              <div
                id="city-filter"
                className={toggleCity ? "expanded-filter" : ""}
              >
                {cityFilter?.map((e) => (
                  <div
                    key={e.name}
                    className="filter-item-box main-font font-14 bold-600 color-4th"
                  >
                    <img
                      onClick={() => {
                        let filter = cityFilter;

                        setCityFilter(
                          filter?.map((i) => {
                            if (e.name === i.name) {
                              i.selected = !i.selected;
                            }
                            return i;
                          })
                        );
                      }}
                      src={!e.selected ? circle1 : circle2}
                      alt=""
                      width={15}
                      height={15}
                    />
                    <div>{e.name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div id="career-content">
            <div className="amount-job main-font font-24 bold-600">
              {amount} Jobs Opening
            </div>
            <div id="jobs-box">
              {filteredData.map((item, i) => {
                return (
                  <JobItem
                    onClick={() => {
                      setShowDetail(item);
                    }}
                    key={i}
                    job={item?.job}
                    city={item?.city}
                    contractType={item?.contractType}
                    amount={item?.amount}
                    open={item?.open}
                    close={item?.close}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
let JobItem = ({ job, city, contractType, amount, open, close, onClick }) => {
  return (
    <div onClick={onClick} className="job-box main-font font-18">
      <div className="job-detail">
        <div className="job-name main-font font-24 bold-600">{job}</div>
        <div className="job-data main-font font-16 bold-600">
          <div className="job-city ">{city}</div>|
          <div className="job-contract ">{contractType}</div>|
          <div className="job-amount ">{amount} Position</div>
        </div>
      </div>
      <div className="job-hire-date main-font font-16 bold-600">
        {open} ~ {close}
      </div>
    </div>
  );
};
let JobItemDetail = ({ job, city, contractType, amount, onClick }) => {
  return (
    <div onClick={onClick} className="job-box-2 color-4th main-font font-18">
      <div className="job-detail">
        <div className="job-name main-font font-24 bold-600">{job}</div>
        <div className="job-data main-font font-16 bold-600">
          <div className="job-city ">{city}</div>|
          <div className="job-contract ">{contractType}</div>|
          <div className="job-amount ">{amount} Position</div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Close</div>
      </div>
    </div>
  );
};
let JobDetail = ({ props }) => {
  let { responsibilities, basicQualifications } = props;
  return (
    <div className="job-detail-box main-font font-16 color-4th">
      <div className="job-detail-textbox">
        <div className="font-24 bold-600">Responsibilities</div>
        <div className="responsibilities-text-describe font-16 bold-500">
          &ensp;{responsibilities?.describe}
        </div>
        <ul className="font-16 bold-500">
          {responsibilities.list?.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
      <div className="job-detail-textbox">
        <div className="font-24 bold-600">Basic Qualifications</div>
        <ul className="font-16 bold-500">
          {basicQualifications?.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
      <div>
        <a
          href="mailto:hr@ripplescommerce.com?subject=Job Application from Website"
          id="career-apply-btn"
          className="main-font font-24 bold-700 color-secondary bg-primary"
        >
          APPLY NOW
          <img src="\Arrow 4.svg" alt="arrow" width={30} />
        </a>
      </div>
    </div>
  );
};
export default FindCareers;
