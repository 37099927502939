let isActivated = false;
export let setNavbar = (path) => {
  try {
    const lastpath = window.location.pathname.substring(1).split('/')[0];
    if (lastpath === "") {
      let link = document.getElementById("/");
      link.classList.remove("selected-page");
    } else {
      let link = document.getElementById(lastpath);
      link.classList.remove("selected-page");
    }
    let link = document.getElementById(path);
    if (path === "contact") {
      link.classList.add("selected-page-v2");
    } else {
      document.getElementById("contact").classList.remove("selected-page-v2");
      link.classList.add("selected-page");
    }
    window.scrollTo(0, 0);
  } catch (error) {}
};
export let checkNavPath = () => {
  const path = window.location.pathname;
  try {
    if (path === "/") {
      let link = document.getElementById("/");
      link.classList.add("selected-page");
    } else {
      let link = document.getElementById(path.substring(1));
      link.classList.add("selected-page");
    }
  } catch (error) {}
};
export let navExpand = () => {
  let navCollapse = document.getElementById("nav-collapse-box");
  let collapseBtn = document.getElementById("nav-collapse");
  if (!isActivated) {
    navCollapse.style = "display:flex;";
    collapseBtn.classList.add("nav-expanded")
  } else {
    navCollapse.style = "display:none;";
    collapseBtn.classList.remove("nav-expanded")

  }
  isActivated = !isActivated;
};
