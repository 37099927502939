import { useEffect, useState } from "react";
import "./index.css";
import { getAll as getAllGroup } from "../../Controller/API/Solution/SolutionGroup";
import { getAll as getAllSolution } from "../../Controller/API/Solution/Solution";
import { imgSource } from "../../Controller/API/API";
const Solutions = () => {
  const [solution, setsolution] = useState([]);
  const [dataGroup, setdataGroup] = useState([])
  useEffect(() => {
    getAllSolution().then((res) => {
      setsolution(res.data);
    });
  }, []);
  useEffect(() => {
    let pre = 0;
    getAllGroup().then((res) => {
      let data = res.data.map((e) => {
        let data = solution
          ?.filter((solution) => {
            return solution.groupId === e._id;
          })
          .map((ele) => {
            return {
              index: ele.index,
              id: ele.targetId,
              topic: ele.topic,
              description: ele.description,
              image: imgSource + ele.image,
            };
          });
          let align = pre
          pre = data.length?data.length:0
        return {
          group: e.topic,
          describe:e.description,
          align: align,
          data:data,
        };
      });
      setdataGroup(data)
    });
  }, [solution]);

  return (
    <div className="layout">
      <div className="main-container solution-container">
        <div id="solution-top-banner" className="top-banner">
          <img
            src="\asset\Asset Other\Subtract1.svg"
            className="asset-sol"
            id="asset1-sol"
            alt=""
          ></img>
          <img
            className="asset-sol"
            id="asset2-sol"
            src="\asset\Asset Other\Subtract2.svg"
            alt=""
          ></img>
          <div id="solution-top-banner-layout">
            <div className="solution-top-banner-topic main-font color-secondary font-48 bold-800">
              Rely on us as the wave that lifts <br />
              your success to new heights.
            </div>
            <div className="solution-top-banner-describe main-font color-secondary font-24 bold-500 align-center">
              Given our extensive expertise as an e-commerce enabler and TikTok
              <br />
              Shop Partner, we provide both end-to-end and customized solutions
              <br />
              to fit your needs. Our solution covers everything from sales &
              <br />
              marketing strategy, e-commerce operations, performance <br />
              marketing, and Live Solutions & Creator management.
            </div>
            <div id="top-banner-btn-container">
              <a href={"solutions#" + "ShopStrategy"}>
                <img
                  src="\asset\ICON\solution\Property 1=SM2.1.svg"
                  alt=""
                  width={140}
                  height={185}
                />
              </a>
              <a href={"solutions#" + "OnlineMarketing"}>
                <img
                  src="\asset\ICON\solution\Property 1=OM1.1.svg"
                  alt=""
                  width={138}
                  height={185}
                />
              </a>
              <a href={"solutions#" + "Fulfillment"}>
                <img
                  src="\asset\ICON\solution\Property 1=FW3.1.svg"
                  alt=""
                  width={150}
                  height={185}
                />
              </a>

              <a href={"solutions#" + "Streaming"}>
                <img
                  src="\asset\ICON\solution\Property 1=LS4.1.svg"
                  alt=""
                  width={141}
                  height={167}
                />
              </a>
              <a href={"solutions#" + "ShortVideo"}>
                <img
                  src="\asset\ICON\solution\Property 1=SV5.1.svg"
                  alt=""
                  width={138}
                  height={167}
                />
              </a>
            </div>
          </div>
        </div>
        {dataGroup.map((e) => (
          <SolutionBox key={e.group} data={e} />
        ))}
      </div>
    </div>
  );
};
const SolutionBox = ({ data }) => {
  return (
    <div key={data.group} className="solution-section">
      <div className="solution-section-group">
        <div className="main-font color-primary font-48 bold-800">
          {data.group}
        </div>
        <div className="main-font color-4th font-24 bold-500">
          {data.describe}
        </div>
      </div>
      {data?.data.map((e, index) => (
        <div
          id={e.id ? e.id : ""}
          key={index}
          className="solution-card-container"
        >
          <div
            // id=

            className={
              (index + data.align) % 2 === 0
                ? "solution-card-bar solution-card-bar-right"
                : "solution-card-bar solution-card-bar-left"
            }
          >
            <div
              className={
                "solution-card-profile " +
                ((index + data.align) % 2 === 0 ? "" : "row-reverse")
              }
            >
              <img src={e.image} className="solution-card-profile-img"></img>
              <div className="solution-card-box">
                <div className="member-card-topic main-font bold-800 color-secondary font-36">
                  {e.before && (
                    <img src={e.before} className="topic-icon"></img>
                  )}
                  {e.topic}
                </div>
                <div className="member-card-describe  main-font bold-500 color-secondary font-16">
                  {e.description}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Solutions;
