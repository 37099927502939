import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import Nav from "./components/Navbar/Nav";
import Footer from "./components/Footer/Footer";
import About from "./pages/About";
import Careers from "./pages/Career";
import Contact from "./pages/Contact";
import Solutions from "./pages/Solutions";
import FindCareers from "./pages/FindCareer/index";

function App() {
  let navigation = [
    { val: "Home", path: "/" },
    { val: "Solutions", path: "solutions" },
    { val: "Careers", path: "careers" },
    { val: "About", path: "about" },
    { val: "Contact", path: "contact" },
  ];
  return (
    <BrowserRouter>
      <Nav navigation={navigation} />

      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="careers">
            <Route index element={<Careers />} />
            <Route path="find-careers">
              <Route index element={<FindCareers />} />
              <Route path=":city/:department" element={<FindCareers />} />
            </Route>
          </Route>

          <Route path="solutions" element={<Solutions />} />
        </Route>
      </Routes>
      <Footer navigation={navigation} />
    </BrowserRouter>
  );
}

export default App;
